<!-- Start chats content -->
<div>
    <div>


        <div style="display: flex;justify-items: center; vertical-align: middle;">
            <a *ngIf="size <= 768" href="{{host}}" class="logo logo-dark" title="Accueil">
                <span class="logo-sm">
                    <img src="assets/images/logo-2.png" alt="colocation" height="60">
                </span>
            </a>

            <span [style.margin-top]="size >768?'0':'25px'">
                <h4>Messagerie</h4>
            </span>


        </div>

        <a *ngIf="size <= 768" class="back" href="{{host}}"><i class="ri-arrow-left-line"></i> retour</a>


        <div *ngIf="!isServiceClient" class="px-2 py-4">
            <ul class="list-unstyled chat-list chat-user-list">
                <li class=" unread"
                    [class.unread]="supportClientLastMessage && supportClientLastMessage['nmbMsgNonLus'] !== 0"
                    style="cursor: pointer;">

                    <ng-container *ngIf="supportClientLastMessage">

                        <a (click)="loadConversationCustomerSupport()" style="cursor: pointer;">

                            <div class="d-flex">
                                <div class="chat-user-img online  align-self-center me-3 ms-0">
                                    <img src="assets/images/customer-support.png" class="avatar-xs" alt="">
                                    <span class="user-status"></span>
                                </div>
                                <div class="flex-1 overflow-hidden">

                                    <div>
                                        <h5 class="text-truncate font-size-15 mb-1 mt-3">
                                            Service Client
                                        </h5>

                                        <p class="chat-user-message text-truncate mb-0">
                                            {{ getContenuNoHtml(supportClientLastMessage?.contenu) }}
                                        </p>
                                    </div>
                                    <div class="font-size-11">{{ isToday(supportClientLastMessage?.date_envoi) ?
                                        (supportClientLastMessage?.date_envoi |
                                            date:
                                            "HH:mm") : (supportClientLastMessage?.date_envoi | date: "dd/MM/yyyy") }}</div>

                                    <div class="unread-message" *ngIf="supportClientLastMessage['nmbMsgNonLus'] !== 0">
                                        <span
                                                class="badge badge-soft-danger rounded-pill">{{supportClientLastMessage['nmbMsgNonLus']
                                        > 9
                                            ?
                                            supportClientLastMessage['nmbMsgNonLus'] : '0' + supportClientLastMessage['nmbMsgNonLus']
                                            }}</span>
                                    </div>

                                    <div class="unread-message" *ngIf="supportClientLastMessage['nmbMsgNonLus'] == 0">
                                        <i style="font-size: 20px;"
                                           *ngIf="supportClientLastMessage['id_expediteur'] != currentUserId"
                                           class="ri-reply-line"></i>
                                        <i style="font-size: 30px;"
                                           *ngIf="supportClientLastMessage['id_expediteur'] == currentUserId && supportClientLastMessage['lu_destinataire'] == 1"
                                           class="ri-check-line" style="color: green;"></i>
                                    </div>

                                </div>

                            </div>
                        </a>
                    </ng-container>


                    <ng-container *ngIf="!supportClientLastMessage">
                        <a (click)="loadConversationCustomerSupport()" style="cursor: pointer;">

                            <div class="d-flex">
                                <div class="chat-user-img online  align-self-center me-3 ms-0">
                                    <img src="assets/images/customer-support.png" class="avatar-xs" alt="">
                                    <span class="user-status"></span>
                                </div>
                                <div class="flex-1 overflow-hidden">

                                    <div>
                                        <h5 class="text-truncate font-size-15 mb-1 mt-3">
                                            Service Client
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </ng-container>


                </li>
            </ul>


        </div>


        <div class=" search-box chat-search-box">
            <div class="input-group mb-3 rounded-3">
                <span class="input-group-text text-muted bg-light pe-1 ps-3" id="basic-addon1">
                    <i class="ri-search-line search-icon font-size-18"></i>
                </span>
                <input type="text" [(ngModel)]="filterContactValue" (ngModelChange)="filterContact($event)"
                       class="form-control bg-light" placeholder="Rechercher contacts" aria-label="Rechercher contacts"
                       aria-describedby="basic-addon1">
            </div>
        </div> <!-- Search Box-->
    </div> <!-- .p-4 -->

    <!-- Start user status -->

    <!-- end user status -->

    <!-- Start chat-message-list -->

    <div class="px-2">
        <h5 *ngIf="!isServiceClient" class="mb-3 px-3 font-size-16">Messages</h5>

        <div
             [ngClass]="isServiceClient ? 'chat-message-list-client' : 'chat-message-list'"
             data-simplebar>

            <ul class="list-unstyled chat-list chat-user-list">

                <ng-container *ngFor="let contact of lisContacts; let i = index;">
                    <li *ngIf="supportClientLastMessage!=contact" class=" unread"
                        [class.unread]="contact['nmbMsgNonLus'] !== 0" style="cursor: pointer;">

                        <div class="d-flex">
                            <!--Div photo-->
                            <a [href]="'/mon-espace?openannonce='+contact.otherPersonIdAnnonce" target="_blank">
                                <div class="chat-user-img  align-self-center me-3 ms-0"
                                     [class.offline]="!contact.connected" [class.online]="contact.connected">
                                    <img [src]="contact?.id_destinataire ===currentUserId?userService.getImageProfilPath(contact?.expediteur):userService.getImageProfilPath(contact?.destinataire)"
                                         [ngClass]="contact?.id_destinataire === currentUserId ? userService.getImageProfilClass(contact?.expediteur) : userService.getImageProfilClass(contact?.destinataire)"
                                         class="avatar-xs" alt="">
                                    <span class="user-status"></span>
                                </div>
                            </a>
                            <a class="flex-1 overflow-hidden"
                               (click)="loadConversation(contact?.id_destinataire !==currentUserId ?contact?.id_destinataire :contact?.id_expediteur)">
                                <!--Div texte-->
                                <div>
                                    <h5 class="text-truncate font-size-15 mb-1 mt-3">{{contact?.destinataire.prenom
                                    && contact?.id_destinataire !== currentUserId ?
                                        contact?.destinataire.prenom
                                        : ''}} {{contact?.expediteur.prenom
                                    && contact?.id_expediteur !== currentUserId ?
                                        contact?.expediteur.prenom
                                        : ''}}
                                        {{ contact?.destinataire.prenom === null
                                        && contact?.id_destinataire !== currentUserId ?
                                            contact?.destinataire.pseudo
                                            : ''}} {{contact?.expediteur.prenom === null
                                        && contact?.id_expediteur !== currentUserId ?
                                            contact?.expediteur.pseudo
                                            : ''}}
                                        {{contact?.destinataire.nom
                                        && contact?.id_destinataire !== currentUserId ?
                                            contact?.destinataire.nom
                                            : ''}} {{contact?.expediteur.nom
                                        && contact?.id_expediteur !== currentUserId ?
                                            contact?.expediteur.nom
                                            : ''}}

                                    </h5>

                                    <p class="chat-user-message text-truncate mb-0"
                                       [class]="'************'===contact?.contenu?'blur_text':''">
                                        {{ getContenuNoHtml(contact?.contenu) }}
                                    </p>
                                </div>
                                <div class="font-size-11">{{ isToday(contact?.date_envoi) ? (contact?.date_envoi |
                                    date:
                                    "HH:mm") : (contact?.date_envoi | date: "dd/MM/yyyy") }}</div>

                                <div class="unread-message" *ngIf="contact['nmbMsgNonLus'] !== 0">
                                    <span class="badge badge-soft-danger rounded-pill">{{contact['nmbMsgNonLus'] > 9
                                        ?
                                        contact['nmbMsgNonLus'] : '0' + contact['nmbMsgNonLus'] }}</span>
                                </div>

                                <div class="unread-message" *ngIf="contact['nmbMsgNonLus'] == 0">
                                    <i style="font-size: 20px;" *ngIf="contact['id_expediteur'] != currentUserId"
                                       class="ri-reply-line"></i>
                                    <i style="font-size: 30px;"
                                       *ngIf="contact['id_expediteur'] == currentUserId && contact['lu_destinataire'] == 1"
                                       class="ri-check-line" style="color: green;"></i>
                                </div>
                            </a>
                        </div>

                    </li>

                </ng-container>

                <h5 *ngIf="!isServiceClient" class="mb-3 px-3 font-size-16">Favoris</h5>
                <ng-container *ngFor="let favorie of lisFavoris">
                    <li style="cursor: pointer;">
                        <a (click)="loadConversation(favorie.id_membre_to )">
                            <div class="d-flex">
                                <a [href]="'/mon-espace?openannonce='+favorie.id_annonce" target="_blank">
                                    <div class="chat-user-img  align-self-center me-3 ms-0"
                                         [class.offline]="!favorie.connected" [class.online]="favorie.connected">
                                        <img [src]="userService.getImageProfilPath(favorie?.membreTo)"
                                             [ngClass]="userService.getImageProfilClass(favorie?.membreTo)"
                                             class="avatar-xs" alt="">
                                        <span class="user-status"></span>
                                    </div>
                                </a>
                                <div class="flex-1 overflow-hidden">
                                    <h5 class="text-truncate font-size-15 mb-1 mt-3">
                                        {{favorie?.membreTo.prenom ? favorie?.membreTo.prenom
                                        : favorie?.membreTo.pseudo
                                        }}
                                        {{favorie?.membreTo.nom }}
                                    </h5>


                                </div>
                                <div class="favorite-item"><i class="ri-shield-star-fill"></i></div>

                            </div>
                        </a>
                    </li>

                </ng-container>


            </ul>

        </div>
    </div>

    <!-- End chat-message-list -->
</div>
<!-- Start chats content -->